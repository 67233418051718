import { render, staticRenderFns } from "./AposContextMenuItem.vue?vue&type=template&id=5bd67773&scoped=true"
import script from "./AposContextMenuItem.vue?vue&type=script&lang=js"
export * from "./AposContextMenuItem.vue?vue&type=script&lang=js"
import style0 from "./AposContextMenuItem.vue?vue&type=style&index=0&id=5bd67773&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bd67773",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/qa/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5bd67773')) {
      api.createRecord('5bd67773', component.options)
    } else {
      api.reload('5bd67773', component.options)
    }
    module.hot.accept("./AposContextMenuItem.vue?vue&type=template&id=5bd67773&scoped=true", function () {
      api.rerender('5bd67773', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ui/ui/apos/components/AposContextMenuItem.vue"
export default component.exports